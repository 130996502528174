import React from "react";
import {Wrapper, ContentDiv} from "./OutlinedGridBox.styles";


const OutlinedGridBox = ({children, className}) => {
    return(
        <Wrapper className={className}>
            <ContentDiv>
                {children}
            </ContentDiv>
        </Wrapper>
    )
}


export default OutlinedGridBox