import styled from "styled-components";

export const Wrapper = styled.div`
    width: calc(100% - 2px);
    display: flex;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--THEME_PRIMARY1);
    border-radius: 4px;
    margin: auto auto var(--vertical_space) auto;
    background-color: var(--THEME_WHITE);
    
    animation: 2s ease-out 0s 1 slideInFromBottom;
    @keyframes slideInFromBottom {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

export const ContentDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
    padding: 40px;
    margin: auto;
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
`;