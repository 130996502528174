import React from "react";
import {Wrapper} from "./Text.styles";

const Text = ({children}) => {

    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default Text;