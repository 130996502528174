import React from "react"
import SEO from "../components/SEO/SEO"
import {useContactPageQuery} from "../hooks/useContactPageQuery";
import PageBody from "../components/PageBody/PageBody";
import OutlinedGridBox from "../components/BOXES/OutlinedGridBox/OutlinedGridBox";
import Text from "../components/Text/Text";
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";


export const StyledImg = styled(GatsbyImage)`
    max-width: 80px;
    img{
        object-fit: contain !important;s
    }
`


const ContactPage = () => {

    const { mdx: {frontmatter : contactPageData}} = useContactPageQuery();

    return(
        <PageBody pageData={contactPageData}>
            <OutlinedGridBox>
                {contactPageData.content.contactData.map(item =>{
                    return(
                        <a href={item.url} target="_blank" key={item.url}>
                            <StyledImg image={item.img.childImageSharp.gatsbyImageData} alt={item.alt}/>
                        </a>
                    )
                })}
            </OutlinedGridBox>
            <Text>Ich freue mich, von Ihnen zu hören.</Text>
        </PageBody>
    )

}

export const Head = () => <SEO title="Kontakt" />

export default ContactPage
