import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  margin-bottom: var(--vertical_space);
  border-top: 1px solid #ddd;
  text-align: center;
  padding: var(--vertical_space);
  font-family: var(--font_text);
`;
