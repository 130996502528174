import {graphql, useStaticQuery} from "gatsby";


export const useContactPageQuery = () => {
    const data = useStaticQuery(graphql`
    query ContactQuery {
      mdx(frontmatter: {slug: {eq: "/contact"}}) {
        frontmatter {
          slug
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          individual_type
          content {
            contactData {
              alt
              img{
                  childImageSharp {
                  gatsbyImageData
                }
              }
              url
            }
          }
        }
      }
    }`)

    return data;
}