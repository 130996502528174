import React from "react";
import Layout from "../Layout/Layout";
import Hero from "../Heros/Hero/Hero";
import {Wrapper, ContentWrapper, PageContent} from "./PageBody.styles";


const PageBody = ({pageData, children}) => {

    return (
        <Layout>
            {pageData.image ? (
                <Hero
                    img={
                        pageData.image
                    }
                />
            ) : null}

            <Wrapper>
                <h1>{pageData.title}</h1>
                <ContentWrapper>
                    <PageContent>
                        {children}
                    </PageContent>
                </ContentWrapper>
            </Wrapper>

        </Layout>
    )
}

export default PageBody;