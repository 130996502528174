import styled from "styled-components"

export const Wrapper = styled.div`
    max-width; 1180px;
    margin: var(--vertical_space) auto 0 auto;
    text-align: center;
    
    > h1:nth-child(1){
        margin-bottom: var(--vertical_space);
        // border-bottom: 1px solid var(--THEME_00);
        // padding-bottom: calc(var(--vertical_space)/2);
    }
`

export const ContentWrapper = styled.div`
    display: block;
`

export const PageContent = styled.article`
    margin-top: 20px;
`